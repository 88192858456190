import useApiInstances from './axios';
import {valueExists} from '../../utils/utils';
import {useSearchParams} from 'react-router-dom';

const useDeposit = () => {
	const {apiBlockmate} = useApiInstances();
	const [searchParams] = useSearchParams();
	const token = searchParams.get('jwt');
	const headers = {
		Authorization: `Bearer ${token}`
	};

	const authorize = async (depositId, name, merchantUrl) => {
		const body = {
			merchant_url: merchantUrl,
		};
		if (valueExists(name)) {
			body.name = name;
		}
		const res = await apiBlockmate.post(
			`v1/exchange/deposit/${depositId}/authorize`,
			body,
			{headers}
		);
		return res?.data?.redirect_url;
	};

	const overview = async (depositId) => {
		const res = await apiBlockmate.get(
			`v1/exchange/deposit/${depositId}/overview`,
			{headers}
		);
		return res?.data;
	};

	const finish = async (depositId, exchangeAccountId, network, secondFactorToken = undefined) => {
		const res = await apiBlockmate.post(
			`v1/exchange/deposit/${depositId}/finish`,
			{
				exchange_account_id: exchangeAccountId,
				network: network,
				second_factor_token: secondFactorToken,
			},
			{headers}
		);
		return res?.data?.redirect_url;
	};

	const back = async (depositId) => {
		return await apiBlockmate.post(
			`v1/exchange/deposit/${depositId}/back`,
			{},
			{headers}
		);
	};

	return {
		authorize,
		overview,
		finish,
		back,
	};
};

export default useDeposit;